<template>
  <v-app>
    <v-overlay :value="download">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <h1 class="mx-auto mt-6 mb-4">{{ this.$route.params.title }}</h1>
    <div
      v-if="loading"
      style="display: flex;align-items: center;justify-content: center;margin: 5em; "
    >
      <v-progress-circular
        :size="70"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <div class="product-container" v-if="hasProducts">
        <div v-for="(product, i) in products" :key="i">
          <Product
            :id="product.id"
            :title="product.nome"
            :desc="product.descricao"
            :pictures="product.pictures"
            @downloadPdf="downloadProduct"
          />
        </div>
      </div>
      <div class="product-container" v-if="!hasProducts">
        <h4>
          Não temos nenhum produto para esta categoria ainda!
        </h4>
      </div>
    </div>
  </v-app>
</template>
<script>
import Bluebird from "bluebird";
import Product from "@/components/product.vue";
import { RepositoryFactory } from "@/repositories/factory";
const ProductsRepository = RepositoryFactory.get("products");

export default {
  components: {
    Product
  },
  data() {
    return {
      products: [],
      hasProducts: true,
      loading: false,
      download: false,
    };
  },
  created() {
    this.$store.dispatch('insertCount');
    this.fetch();
  },
  methods: {
    downloadProduct(productId) {
      this.download = true;
      Bluebird.resolve(ProductsRepository.downloadPDF(productId))
      .then(
        ({ 
          data
        }) => {
          if(data.length > 0) {
            const [{ bytesarq_base64, nome }] = data
            let a = document.createElement("a");
            a.href = "data:application/octet-stream;base64,"+bytesarq_base64;
            a.download = `${nome}.pdf`
            a.click();
          }

          this.download = false;
        })
        .finally(() => {
          this.download = false;
        });
    },
    fetch() {
      this.loading = true;
      Bluebird.resolve(
        ProductsRepository.findAllByCategory(this.$route.query.categoria)
      )
        .then(({ data: products }) =>
          Bluebird.map(products, this.fetchProductDetails, { concurrency: 1 })
        )
        .then(productsMounted => {
          this.products = productsMounted;
          this.hasProducts = productsMounted.length > 0 ? true : false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchProductDetails(product) {
      return Bluebird.resolve(
        ProductsRepository.findProductPicture(product.id)
      ).then(({ data }) => ({
        pictures: data,
        ...product
      }));
    }
  }
};
</script>
<style>
.product-container {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
