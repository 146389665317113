<template>
  <v-app>
    <h1 class="mx-auto mt-6">Escolha uma categoria:</h1>
    <div
      v-if="loading"
      style="display: flex;align-items: center;justify-content: center;margin: 5em; "
    >
      <v-progress-circular
        :size="70"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-if="!loading" class="category-container">
      <div v-for="(category, i) in categories" :key="i">
        <Category
          :id="category.id"
          :title="category.nome"
          :desc="category.descricao"
          :pictures="category.pictures"
        />
      </div>
    </div>
  </v-app>
</template>
<script>
import Bluebird from "bluebird";
import Category from "@/components/category.vue";
import { RepositoryFactory } from "@/repositories/factory";
const CategoriesRepository = RepositoryFactory.get("categories");

export default {
  components: {
    Category
  },
  data() {
    return {
      categories: [],
      loading: false
    };
  },
  created() {
    this.fetch();
    this.$store.dispatch('insertCount');
  },
  methods: {
    fetch() {
      this.loading = true;

      Bluebird.resolve(CategoriesRepository.findAll())
        .then(({ data: categories }) =>
          Bluebird.map(categories, this.fetchCategoryDetails, {
            concurrency: 1
          })
        )
        .then(categoriesMounted => {
          this.categories = categoriesMounted;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCategoryDetails(category) {
      return Bluebird.resolve(
        CategoriesRepository.findCategoryPicture(category.id)
      ).then(({ data }) => ({
        pictures: data,
        ...category
      }));
    }
  }
};
</script>
<style>
.category-container {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
