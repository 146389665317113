<template>
  <v-row>
    <v-col
      v-for="(client, i) in clients"
      :key="i"
      class="d-flex child-flex col-md-2 col-4 col-lg-2 col-xs-2"
    >
      <v-img
        :src="mountPicture(client, keyToPicture)"
        @click="downloadClient(client.id)"
        contain
        aspect-ratio="1"
        max-width="200"
        class="white"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    clients: {
      type: Array
    },
    keyToPicture: {
      type: String,
      required: false,
      default: "bytesarq_base64"
    }
  },
  methods: {
    mountPicture(picture, keyToPicture) {
      return `data:image/jpeg;base64,${picture[keyToPicture]}`;
    },
    downloadClient(clientId) {
      this.$emit("clientId", clientId);
    }
  }
};
</script>
