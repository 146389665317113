import { render, staticRenderFns } from "./Clients.vue?vue&type=template&id=ac60e132&"
import script from "./Clients.vue?vue&type=script&lang=js&"
export * from "./Clients.vue?vue&type=script&lang=js&"
import style0 from "./Clients.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VApp,VProgressCircular})
