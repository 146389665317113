<template v-slot:default="{ hover }">
  <v-container class="pa-2 text-center fill-height" @click="downloadProduct(id)">
    <v-row class="fill-height my-4 mx-2" align="center" justify="center">
      <v-hover v-slot:default="{ hover }">
        <v-card
          class="mx-auto"
          max-width="300"
          min-height="450"
          max-height="650"
        >
          <v-img 
            v-if="pictures.length > 0" 
            contain
            :src="localPicture" 
            min-width="300"
            max-height="300" 
            min-height="300"/>

          <v-card-text>
            <h2 class="title primary--text" v-text="title" />
            <p>
              {{ handleDesc() }}
            </p>
          </v-card-text>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
              color="#3f51b5"
              style="z-index:1 !important"
            >
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </v-hover>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    id: {
      required: false,
      type: Number
    },
    title: {
      required: true,
      type: String
    },
    desc: {
      required: true,
      type: String
    },
    featuredDesc: {
      required: false,
      type: String
    },
    isFeatured: {
      required: false,
      type: Boolean,
      default: false
    },
    pictures: {
      required: false,
      type: Array
    }
  },
  data() {
    return {
      overlay: false,
      dialog: false,
      localPicture: null,
      localDesc: null,
    };
  },
  created() {
    if (this.pictures.length > 0) {
      this.localPicture = this.mountPicture(this.pictures);
    }
  },
  methods: {
    closeDialog(e) {
      this.dialog = e;
    },
    mountPicture(pictures) {
      const prefixBase64 = `data:image/jpeg;base64,`;

      if (this.isFeatured && this.pictures.some(picture => picture.isCapa)) {
        return `${prefixBase64}${
          this.pictures.find(picture => picture.isCapa).BytesArq_Base64
        }`;
      }

      return `${prefixBase64}${pictures[0].bytesarq_base64}`;
    },
    handleDesc() {
      if (
        this.isFeatured &&
        this.featuredDesc
      ) {
        return this.featuredDesc;
      }

      return this.desc;
    },
    downloadProduct(productId) {
      this.$emit("downloadPdf", productId);
    },
  }
};
</script>
<style>
.v-overlay__scrim {
  cursor: pointer;
}
</style>
