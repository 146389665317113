<template>
  <v-app>
    <div class="video-container">
      <video autoplay muted loop>
        <source src="@/assets/tower.mp4" type="video/mp4" />
      </video>
      <div class="caption">
        <h1>Clientes</h1>
      </div>
    </div>
    <div
      v-if="loading"
      style="display: flex;align-items: center;justify-content: center;margin: 5em; "
    >
      <v-progress-circular
        :size="70"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-if="!loading" class="mt-8 mb-8">
      <div class="row">
        <div class="col-1"></div>
        <Clients :clients="clients" @clientId="downloadClient" />
        <div class="col-1"></div>
      </div>
    </div>
  </v-app>
</template>
<script>
import Bluebird from "bluebird";
import Clients from "@/components/clients.vue";
import { RepositoryFactory } from "@/repositories/factory";
const ContentRepository = RepositoryFactory.get("contents");
export default {
  components: {
    Clients
  },
  data() {
    return {
      clients: null,
      loading: false
    };
  },
  created() {
    this.fetch();
    this.$store.dispatch('insertCount');
  },
  methods: {
    downloadClient(clientId) {
      Bluebird.resolve(ContentRepository.downloadPDF(clientId)).then(
        ({ bytesarq_base64 }) => {
          window.open("data:application/pdf," + encodeURI(bytesarq_base64));
        }
      );
    },
    fetch() {
      this.loading = true;
      Bluebird.resolve(ContentRepository.findClients())
        .then(({ data }) => {
          this.clients = data;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style>
.video-container {
  height: 300px;
  width: 100%;
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

/* Just styling the content of the div, the *magic* in the previous rules */
.video-container .caption {
  font-size: 2.5vh !important;
  z-index: 1;
  position: relative;
  text-align: center;
  color: #fff;
  padding: 10px;
  padding-top: 150px;
  padding-bottom: 130px;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
